<template>
  <v-row>
    <v-col cols="12" md="12">
      Método configurado: {{ metodo_correo?.nombre }}
      <v-autocomplete
        :items="metodos_correo"
        outlined
        item-text="nombre"
        item-value="id"
        label="Seleccione el método a configurar"
        @change="cambiarMetodoCorreo"
        v-model="metodo_correo_seleccionado"
      ></v-autocomplete>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "Metodocorreo",
  data() {
    return {
      metodo_correo_seleccionado: null,
      metodos_correo: [],
      metodo_correo: null,
    };
  },
  watch: {
    id_usuario: {
      handler(val) {
        if (val) {
          this.obtenerConfiguracionCorreo();
        } else {
          this.metodo_correo = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  props: {
    id_usuario: {
      type: Number,
    },
  },
  methods: {
    async obtenerConfiguracionCorreo() {
      const {
        data: { metodo, metodos },
      } =
        await this.services.administracionCorreoServices.obtenerConfiguracionUsuario(
          this.id_usuario
        );
      this.metodo_correo = metodo;
      this.metodos_correo = metodos;
    },
    async cambiarMetodoCorreo() {
      if (!this.metodo_correo_seleccionado) return;
      await this.services.administracionCorreoServices.configurarMetodoCorreo(
        this.id_usuario,
        this.metodo_correo_seleccionado
      );
      await this.obtenerConfiguracionCorreo();
      this.metodo_correo_seleccionado = null;
    },
  },
};
</script>
