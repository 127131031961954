<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-data-table
        :headers="headers_metodos"
        :items="metodos"
        no-data-text="No tiene métodos de autenticación registrados"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:[`item.nombre`]="{ item }">
          <v-icon
            :color="
              item.MetodoAutenticacionUsuario.is_primary ? 'green' : 'red'
            "
          >
            {{ item.icono }}
          </v-icon>
          {{ item.nombre }}
        </template>
        <template v-slot:[`item.acciones`]="{ item }">
          <v-tooltip top v-if="!item.permite_eliminar">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                @click="
                  (modal_confirmar_cambio = true),
                    (metodo = item.MetodoAutenticacionUsuario.id)
                "
                >mdi-check-decagram-outline</v-icon
              >
            </template>
            <span> Establecer como principal</span>
          </v-tooltip>
          <v-tooltip top v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                @click="
                  (modal_confirmar_eliminacion = true),
                    (metodo = item.MetodoAutenticacionUsuario.id)
                ">
                mdi-alpha-x-box
                </v-icon>
            </template>
            <span> Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <ConfirmationDialogComponent
        :show="modal_confirmar_cambio"
        btnConfirmar="Realizar cambio"
        :title="`¿Desea realizar el cambio de método de autentiación?`"
        message="Dependiendo del método, este funcionará de manera temporal, es decir, volverá a la normalidad luego del siguiente inicio de sesión."
        @close="modal_confirmar_cambio = false"
        @confirm="realizarCambio"
      />
      <ConfirmationDialogComponent
        :show="modal_confirmar_eliminacion"
        btnConfirmar="Realizar cambio"
        :title="`¿Desea eliminar el método de autentiación?`"
        message="Esta acción será permanente."
        @close="modal_confirmar_eliminacion = false"
        @confirm="eliminar"
      />
    </v-col>
  </v-row>
</template>
<script>
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";

export default {
  name: "MetodoAutenticacion",
  data() {
    return {
      headers_metodos: [
        {
          text: "Metodo",
          align: "start",
          sortable: false,
          value: "nombre",
          width: "25%",
        },
        {
          text: "Descripción",
          align: "start",
          sortable: false,
          value: "descripcion",
          width: "25%",
        },
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "acciones",
          width: "25%",
        },
      ],
      metodos: [],
      modal_confirmar_cambio: false,
      modal_confirmar_eliminacion: false,
      metodo: null,
      metodo_correo_seleccionado: null,
    };
  },
  components: { ConfirmationDialogComponent },
  props: {
    id_usuario: {
      type: Number,
    },
  },
  watch: {
    id_usuario: {
      handler(val) {
        if (val) {
          this.obtenerMetodosUsuario();
        } else {
          this.metodos = [];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async obtenerMetodosUsuario() {
      const { data } = await this.services.Autenticacion.obtenerMetodosUsuario(
        this.id_usuario
      );
      this.metodos = data;
    },
    async realizarCambio() {
      console.log('asdasd')
      await this.services.Autenticacion.actualizarMetodo(
        this.id_usuario,
        this.metodo
      );
      this.metodo = null;
      this.modal_confirmar_cambio = false;
      await this.obtenerMetodosUsuario();
    },
    async eliminar() {
      await this.services.Autenticacion.eliminarMetodo(this.metodo);
      this.metodo = null;
      this.modal_confirmar_eliminacion = false;
      await this.obtenerMetodosUsuario();
    },
  },
};
</script>
