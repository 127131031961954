<template>
  <v-row>
    <v-col cols="12" md="8">
      <v-autocomplete
        outlined
        clearable
        :items="catalogo"
        item-text="nombre"
        return-object
        v-model="permiso_agregar"
      />
    </v-col>
    <v-col cols="12" md="4" class="pt-6">
      <v-btn @click="agregarPermiso" :disabled="!permiso_agregar">
        Agregar permiso
      </v-btn>
    </v-col>
    <template v-if="permiso_agregar?.limita_documentos">
      <v-col cols="12">
        <v-text-field
          label="Digite la cantidad de documentos diarios a permitir"
          outlined
          clearable
          v-model="extra_config.doc_permitidos"
          type="number"
        />
      </v-col>
    </template>
    <v-col>
      <v-data-table
        :headers="headers"
        :items="permisos"
        no-data-text="No tiene permisos extras registrados"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:[`item.info`]="{ item }">
          {{
            item.cantidad > 0 ? `Cantidad de descargas: ${item.cantidad}` : ""
          }}
        </template>
        <template v-slot:[`item.acciones`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="red"
                v-on="on"
                v-bind="attrs"
                @click="(modal_borra = true), (permiso_borrar = item.id)"
                >mdi-trash-can</v-icon
              >
            </template>
            <span> Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-col>
    <ConfirmationDialogComponent
      :show="modal_borra"
      btnConfirmar="Eliminar permiso"
      :title="`¿Desea eliminar el permiso?`"
      message="Esta acción será permanente."
      @close="modal_borra = false"
      @confirm="removerPermiso"
    />
  </v-row>
</template>
<script>
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";

export default {
  name: "permisosExtra",
  components: { ConfirmationDialogComponent },
  watch: {
    id_usuario: {
      async handler(val) {
        if (val) {
          await this.obtenerMetodod();
        } else {
          this.permiso_agregar = null;
          this.permisos = [];
        }
      },
      deep: true,
      immediate: true,
      cargando: false,
    },
  },
  props: {
    id_usuario: {
      type: Number,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Permiso",
          align: "start",
          sortable: false,
          value: "CatalogoPermisosExtra.nombre",
          width: "25%",
        },
        {
          text: "informacion adicional",
          align: "start",
          sortable: false,
          value: "info",
          width: "25%",
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "acciones",
          width: "25%",
        },
      ],
      permisos: [],
      catalogo: [],
      permiso_agregar: null,
      extra_config: {},
      permiso_borrar: null,
      modal_borra: false,
    };
  },
  methods: {
    async obtenerMetodod() {
      const { data } = await this.services.permisosExtraServices.getPermisos(
        this.id_usuario
      );
      this.permisos = data;
    },
    async obtenerCatalotoPermisos() {
      const { data } =
        await this.services.permisosExtraServices.catalogoPermisos();
      this.catalogo = data;
    },
    async agregarPermiso() {
      await this.services.permisosExtraServices.agregarPermiso({
        data: { ...this.extra_config },
        idUsuario: this.id_usuario,
        idPermiso: this.permiso_agregar.id,
      });
      this.permiso_agregar = {};
      await this.obtenerMetodod();
    },
    async removerPermiso() {
      await this.services.permisosExtraServices.deletePermiso(
        this.permiso_borrar
      );
      this.permiso_borrar = null;
      this.modal_borra = false;
      await this.obtenerMetodod();
    },
  },
  async mounted() {
    await this.obtenerCatalotoPermisos();
  },
};
</script>
